import React from "react"
import { graphql } from "gatsby"
import { styled, media } from "../components/mediaCSS"
import Layout from "../components/layout"
import Map from "../components/Map"
import SEO from "../components/seo"
import { FaPizzaSlice } from "react-icons/fa"

const LocationContainer = styled.div`
  margin: 20px 40px;

  display: flex;
  flex-direction: column;
  border: 2px solid black;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0px;
    padding: 0px;
  }
  > * {
    flex: 1;
    text-align: center;
    padding: 10px 0px 10px 0px;
  }

  ${media.tablet_desktop` flex-direction: row; `}
`

const Announcement = styled.div`
  background-color: #e51937;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 20px;
  margin-top: 10px;
  justify-content: space-around;

  .pizzaIcon {
    font-size: 30px;
  }
`

export default ({ data }) => {
  const venues = data.allVenue.nodes

  return (
    <Layout>
      <SEO title="Location" />
      <Map locations={venues.filter(v => v.isActive)}></Map>

      {process.env.GATSBY_ID_clients === "56" && (
        <Announcement>
          <FaPizzaSlice className="pizzaIcon" />

          <span>New store in Houston (Spring) Now Open! 346-781-6262</span>
          <FaPizzaSlice className="pizzaIcon" />
        </Announcement>
      )}

      {venues
        .filter(v => v.isActive)
        .map(loc => {
          const { venueNameShort, address1, address2, phone, sitePath } = loc
          return (
            <LocationContainer key={loc.venueNameShort}>
              <div>
                <h3>{venueNameShort}</h3>
                <div>{address1}</div>
                <div>{address2}</div>
              </div>

              <a href={`tel:${phone}`}>{phone}</a>
              <a href={"/" + sitePath}>View</a>
            </LocationContainer>
          )
        })}
    </Layout>
  )
}

export const query = graphql`
  query {
    allVenue {
      nodes {
        ID_clients
        isActive
        address1
        address2
        venueName
        venueNameShort
        longitude
        latitude
        sitePath
        phone
        hours {
          CloseTime
          DayOfWeek
          OpenTime
        }
      }
    }

    allSite {
      pageInfo {
        perPage
      }
      nodes {
        siteMetadata {
          locations {
            lat
            lng
            name
            address1
            address2
            website
            phone
            moreInfo
            orderUrl
            doordash
            favor
            cavier
            grubhub
          }
        }
      }
    }
  }
`
